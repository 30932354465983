<template lang="pug">
    v-btn(class="mr-3" rounded small :loading="loading" :disabled="loading")
      v-file-input(v-model="tempPhotos" label="File input" hide-input multiple filled prepend-icon="mdi-image-plus" @change="addPhoto")
</template>


<script>
    import { fileService } from '@/_services'

    export default {
        props: ['item'],
        data: () => ({
          tempPhotos: [],
          dataPhotos: [],
          loading: false
        }),
        methods: {
          addPhoto() {
            this.dataPhotos = []
            if (this.tempPhotos.length > 0) {
              this.upload()
            }
          },
          upload() {
            this.loading = true
            let formData = new FormData()
            formData.append("files[]", this.tempPhotos.pop());
            fileService.uploadFiles(formData).then(data => {
              this.dataPhotos = this.dataPhotos.concat(data)
              if (this.tempPhotos.length != 0) {
                this.upload()
              } else {
                this.item.tempPhotos = this.item.tempPhotos.concat(this.dataPhotos);
                this.loading = false
              }
            }).catch(error => console.log(error))
          }
        }
    }
</script>

<style>
    .text-nowrap {
      white-space: nowrap !important;
    }
    .v-btn .v-input__prepend-outer {
      margin-top: 4px;
      margin-right: 0;
    }
</style>