<template lang="pug">
  div
    v-row(v-if="isCustomer || !show_report")
      v-col(cols="12") Отчёт отсутствует
    v-row(v-else)
      v-col(cols="12")
        v-data-table(:headers="headers" :items="report" hide-default-footer class="elevation-1")
          template(v-slot:item.date="{ item }")
            v-edit-dialog(:return-value="item.date" large @save="saveDate(item)" @open="openDate(item)") {{ item.date }}
              v-icon mdi-pencil
              template(v-slot:input)
                v-menu(v-model="menu" transition="scale-transition" offset-y max-width="290px" min-width="auto")
                  template(v-slot:activator="{ on, attrs }")
                    v-text-field(v-model="computedDateFormatted"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on")
                  v-date-picker(v-model="date" no-title @input="menu = false" :max="new Date().toISOString().substr(0, 10)" first-day-of-week="1")
          template(v-slot:item.action="{ item }")
            div(class="text-nowrap")
              v-btn(rounded small @click="create" class="mr-3")
                v-icon mdi-content-save
              Photo(:item="prereport")
      v-col(cols="12" v-if="comment")
        v-simple-table(class="elevation-1")
          template(v-slot:default)
            thead
              tr
                th(class="text-center") Комментарий
            tbody
              tr
                td {{ comment }}
      Photos(:photos="prereport.tempPhotos" :item="prereport" v-if="prereport.tempPhotos.length > 0")
      v-col(cols="12")
        v-data-table(:headers="headersdata" :items="reportdata" :footer-props="footer_props" class="elevation-1")
          template(v-slot:item="{ item }")
            tr
              td(v-for="(header, i) in headersdata" :key="i")
                template(v-if="header.value != 'action'") {{ header.presense && item[header.value] == null ? header.def : item[header.value] }}
                div(class="text-nowrap" v-else)
                  v-btn(rounded small class="mr-3" @click="edit(item)")
                    v-icon mdi-pencil
        v-dialog(v-model="dialog" max-width="400px")
          v-card
            v-card-title
              span(class="headline") {{ current.product }}
            v-card-text
              v-container
                v-row
                  v-col(cols="12" :sm="fields.length > 1 ? 6 : 12" v-for="field in fields" :key="field.uuid")
                    v-text-field(v-if="field.fieldType.name == 'INT'" type="number" :rules="rules.int" :label="field.name" v-model.number="current.fields[field.uuid]['value']")
                    v-text-field(v-else-if="field.fieldType.name == 'FLOAT'" type="number" :rules="rules.float" :label="field.name" v-model.number="current.fields[field.uuid]['value']")
                    v-text-field(v-else-if="field.fieldType.name == 'DATE'" :label="field.name" v-model="current.fields[field.uuid]['value']")
                    v-text-field(v-else-if="field.fieldType.name == 'STRING'" :label="field.name" v-model="current.fields[field.uuid]['value']")
                    v-text-field(v-else-if="field.fieldType.name == 'BOOLEAN'" :label="field.name" v-model="current.fields[field.uuid]['value']")
                    v-select(v-else-if="field.fieldType.name == 'SELECT'" item-text="value" item-value="value" :items="field.options" :label="field.name" v-model="current.fields[field.uuid]['value']")
            v-card-actions
              v-spacer
              v-btn(color="blue darken-1" text @click="close") Отменить
              v-btn(color="blue darken-1" text @click="save") Сохранить
        v-dialog(v-model="dialogCreate" max-width="500px")
          v-card
            v-card-title(class="headline") Создать отчёт?
            v-card-actions
              v-spacer
              v-btn(color="blue darken-1" text @click="no") Нет
              v-btn(color="blue darken-1" text @click="yes") Да
        v-snackbar(color="green" v-model="snackbar.open" :timeout="snackbar.timeout") {{ snackbar.text }}
</template>


<script>
    import { reportService } from '@/_services'
    import { mapMutations, mapGetters } from "vuex";
    import Photos from './TmpPhotos'
    import Photo from './TmpPhoto'

    export default {
        components: {
            Photos,
            Photo
        },
        props: ['id', 'project'],
        data: () => ({
            show_report: false,
            snackbar: {
              text: '',
              open: false,
              timeout: 2000
            },
            headers: [
              { text: 'Время', value: 'date' },
              { text: 'Проект', value: 'project' },
              { text: 'Ретейлер', value: 'retailer' },
              { text: 'Адрес', value: 'address' },
              { text: 'Мерч', value: 'user' },
              { text: 'Действия', value: 'action' }
            ],
            report: [],
            fields: [],
            headersdata: [],
            reportdata: [],
            comment: '',
            dialog: false,
            dialogCreate: false,
            current: {
              fields: {}
            },
            rules: {
              int: [ val => /\d/.test(val) || 'Это не число', val => (val || '').length > 0 || 'Пустое поле' ],
              float: [ val => /[\d.]/.test(val) || 'Это не число', val => (val || '').length > 0 || 'Пустое поле' ]
            },
            date: new Date().toISOString().substr(0, 10),
            menu: false,
            presenses: ['V','OOS','OUT','middle','low'],
            prereport: {
              projectId: 0,
              tradePointId: 0,
              comment: '',
              tempPhotos: [],
              photos: [],
              date: new Date().toISOString().substr(0, 10),
              data: []
            },
            footer_props: {
                'items-per-page-options': [25, 50, 100]
            }
        }),
        mounted() {
          this.loadReport()
        },
        computed: {
            ...mapGetters("authentication", ["checkRole"]),
            isCustomer() {
              return this.checkRole(0)
            },
            computedDateFormatted() {
              return this.dateFormat(new Date(Date.parse(this.date)))
            },
            photos() {
              return []
            }
        },
        methods: {
            ...mapMutations(["setAppBackLink"]),
            loadReport() {
                this.loadingData(reportService.newPoint, { point: this.id, project: this.project }, data => {
                  this.show_report = true;
                  this.prereport.tradePointId = this.id;
                  this.prereport.projectId = this.project;
                  let date = new Date();
                  this.comment = data.comment
                  this.fields = [].concat(data.reportField)
                  this.fields.push({
                    uuid: '',
                    name: 'Наличие',
                    options: ['V','OOS','OUT','middle','low', ''],
                    fieldType: {
                      name: 'SELECT',
                      value: 5
                    }
                  })
                  this.fields.push({
                    uuid: '-1',
                    name: 'Комментарий',
                    fieldType: {
                      name: 'STRING',
                      value: 0
                    }
                  })
                  let arr = this.fields.map(field => Object.assign({ reportFieldUuid: field.uuid, reportFieldDataUuid: '', value: '' }, field))
                  for(let item of arr) {
                    this.current.fields[item.uuid] = item
                  }
                  this.report = []
                  this.report.push(data)
                  this.report[0].date = this.dateFormat(date)
                  this.date = date.toISOString().substr(0, 10)
                  this.headersdata = [
                    { text: 'Продукт', value: 'product', sortable: false },
                    { text: 'Наличие', value: 'presense', sortable: false },
                    { text: 'Комментарий', value: 'comment', sortable: false }
                  ]
                  this.reportdata = []
                  for(let i = 0, rfd = data.reportField; i < rfd.length;i++) {
                    this.headersdata.push({
                      text: rfd[i].name, value: rfd[i].uuid, sortable: false, presense: rfd[i].presense, def: rfd[i].defValue
                    })
                  }
                  this.headersdata.push({ text: 'Действия', value: 'action' })
                  for(let i = 0, rd = data.reportData; i < rd.length;i++) {
                    this.reportdata.push(this.parseData(rd[i]))
                  }
                  this.reportdata.sort((a, b) => a.product > b.product ? 1 : a.product < b.product ? -1 : 0)
                })
            },
            save() {
              let item = this.prereport.data.find(d => d.product == this.current.id);
              if (item == null)
                this.prereport.data.push({
                  product: this.current.id,
                  fields: JSON.parse(JSON.stringify(Object.values(this.current.fields)))
                })
              else item.fields = Object.values(this.current.fields)
              let idx = this.reportdata.findIndex((el, i, arr) => el.id == this.current.id)
              if (idx != -1) {
                let elem = this.reportdata[idx];
                for(let it in this.current.fields) {
                  if (it == '') {
                    elem.presense = this.current.fields[it].value
                  } else if (it == '-1') {
                    elem.comment = this.current.fields[it].value
                  } else {
                    elem[it] = this.current.fields[it].value
                  }
                }
                elem.field = this.fields.map(f => Object.assign({}, { field: f, value: elem[f.uuid], id: '' }))
              }
              this.reportdata = [].concat(this.reportdata)
              this.dialog = false
            },
            close() {
              this.dialog = false
            },
            edit(item) {
              this.current.id = item.id
              this.current.product = item.product
              for(let it in this.current.fields) {
                if (it == '') {
                  this.current.fields[it].value = item.presense
                } else if (it == '-1') {
                  this.current.fields[it].value = item.comment
                } else {
                  let o = item.field.find(i => i.field.uuid == it)
                  if (o != null) {
                    this.current.fields[it].value = o.value
                    this.current.fields[it].reportFieldDataUuid = o.id
                  } else this.current.fields[it].value = 0
                }
              }
              this.dialog = true
            },
            yes() {
              this.loadingData(reportService.sendPreReport, this.prereport, data => {
                this.alert('Отчёт создан');
                this.show_report = false;
                this.$router.push(`/report/${data.uuid}`);
              }, error => {
                alert(error.response.data.status == 406 && error.response.data.message ? error.response.data.message : 'Произошла ошибка на сервере');
                this.no();
              });
            },
            no() {
              this.dialogCreate = false
            },
            create() {
              this.dialogCreate = true
            },
            saveDate(d) {
              this.prereport.date = this.date
              d.date = this.computedDateFormatted
            },
            openDate(d) {
              let [day, month, year] = d.date.split('.')
              this.date = new Date(Date.parse(`${year}-${month}-${day}`)).toISOString().substr(0, 10) 
            },
            dateFormat(date) {
                return `${date.getDate() < 10? '0' : ''}${date.getDate()}.${date.getMonth() + 1 < 10? '0' : ''}${date.getMonth() + 1}.${date.getFullYear()}`
            },
            parseData(data) {
              let obj = { id: data.id, product: data.product, presense: data.presense, field: data.reportFieldData }
              for(let j = 0, rfd = data.reportFieldData; j < rfd.length;j++) {
                obj[rfd[j].field.uuid] = rfd[j].value
              }
              return obj
            },
            alert(text) {
              this.snackbar.text = text;
              this.snackbar.open = true
            }
        }
    }
</script>

<style>
    .text-nowrap {
      white-space: nowrap !important;
    }
    .v-btn .v-input__prepend-outer {
      margin-top: 4px;
      margin-right: 0;
    }
</style>